<template>
  <div class="loveDonateList">
    <div class="tab">
      <div class="item">
        <div
          :class="['item-l', { active: isDonate == 1 }]"
          @click="selectTab(1)"
        >
          我的捐款
        </div>
        <div
          :class="['item-r', { active: isDonate == 2 }]"
          @click="selectTab(2)"
        >
          我的受捐
        </div>
      </div>
    </div>
    <div class="list">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        ref="list"
        :finishedText="finishedText"
      >
        <div v-for="(item, index) in listData" :key="index">
          <div class="item" @click="toDetail(item)">
            <div class="item-l">
              <div class="money" v-if="isDonate == 1">
                捐款金额：{{ item.money }}元
              </div>
              <div class="des" v-else>受捐说明：{{ item.discribe }}元</div>
              <div class="time">发起时间：{{ item.createTime }}</div>
            </div>
            <div :class="['item-r', reClass(item.status)]">
              {{ auditStatusOpsMap[item.status] }}
            </div>
          </div>
        </div>
      </v-list>
    </div>
  </div>
</template>

<script>
import { toRegister, gloabalCount } from "@/utils/common.js";
import { getDonationListUrl } from "./api.js";
import { auditStatusOps, auditStatusOpsMap } from "./map.js";
var moment = require("moment");
export default {
  name: "loveDonateList",
  data() {
    return {
      finishedText: "没有更多了",
      finished: false,
      auditStatusOps,
      auditStatusOpsMap,
      listData: [],
      isDonate: 1,
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    this.isDonate = this.$route.query.isDonate == 2 ? 2 : 1;
  },
  mounted() {},
  methods: {
    toDetail(item) {
      if (this.isDonate == 1) {
        this.$router.push({
          name: "donateSubmit",
          query: {
            id: item.id,
          },
        });
      } else {
        this.$router.push({
          name: "todonateSubmit",
          query: {
            id: item.id,
          },
        });
      }
    },
    selectTab(data) {
      this.isDonate = data;
      this.listData = [];
      this.requestData.curPage = 1;
      this.finished = false;
      this.getloveDonateListList();
    },
    reClass(item) {
      for (let i = 0; i <= auditStatusOps.length - 1; i++) {
        if (auditStatusOps[i].value == item) {
          return auditStatusOps[i].class;
        }
      }
    },
    async getloveDonateListList() {
      let params = {
        fromFlag: 1,
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        donationType: this.isDonate,
        donationUserId: this.userId,
        tenantId: this.tenantId,
      };
      let res = await this.$axios.get(`${getDonationListUrl}`, {
        params: params,
      });
      if (res.code === 200) {
        if (res.data.pages <= res.data.current) {
          this.finished = true;
        } else {
          this.$refs.list._data.loading = false;
        }
        this.finishedText = "没有更多了";
        if (res.data.records.length === 0) {
          this.finishedText = "";
          return;
        }
        if (this.requestData.curPage == 1) {
          this.listData = res.data.records;
        } else {
          res.data.records.forEach((item) => {
            this.listData.push(item);
          });
        }
      } else {
      }
      // this.requestData.curPage++;
    },
    onLoad() {
      this.getloveDonateListList();
    },
  },
};
</script>
<style lang="less" scoped>
.loveDonateList {
  min-height: 100vh;
  padding-top: 40px;
  .tab {
    .item {
      display: flex;
      justify-content: space-around;
      font-weight: 600;
      font-size: 32px;
      color: rgba(0, 0, 0, 0.5);
      line-height: 44px;
      .item-l,
      .item-r {
        padding: 12px 0;
        position: relative;
      }
      .active {
        color: #3781ff;
        &::after {
          content: "";
          position: absolute;
          width: 34px;
          height: 6px;
          background: #3781ff;
          border-radius: 3px;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
        }
      }
    }
  }
  .list {
    padding: 0 28px;
    margin-top: 30px;
    .item {
      height: 160px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
      border-radius: 16px;
      padding: 28px;
      margin-bottom: 20px;
      box-sizing: border-box;
      position: relative;
      .item-l {
        .money {
          font-weight: 700;
          font-size: 28px;
          color: rgba(0, 0, 0, 0.85);
          line-height: 40px;
          margin-bottom: 18px;
        }
        .des {
          font-weight: 700;
          font-size: 28px;
          color: rgba(0, 0, 0, 0.85);
          line-height: 40px;
          margin-bottom: 18px;
          width: 450px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .time {
          font-weight: 400;
          font-size: 24px;
          color: rgba(0, 0, 0, 0.5);
          line-height: 34px;
        }
      }
      .item-r {
        width: 160px;
        height: 40px;
        background: rgba(243, 142, 45, 0.1);
        border-radius: 8px;
        text-align: center;
        font-weight: 400;
        font-size: 24px;
        color: #f38e2d;
        position: absolute;
        right: 44px;
        top: 60px;
        line-height: 40px;
      }
      .toexamineOut {
        background: rgba(224, 32, 32, 0.1);
        color: #e02020;
      }
      .toexamineEd {
        background: rgba(54, 207, 124, 0.1);
        color: #36cf7c;
      }
    }
  }
}
</style>
