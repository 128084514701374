//众创空间申请
const applyCreateWorkList = `/gateway/hc-external/makerSpace/doApply`;
//获取活动列表
const getActivityListUrl = `/gateway/hc-serve/miniapi/activity/activityList`;
//捐赠
const saveDonationUrl = `/gateway/hc-neighbour/donation/saveDonation`;
//爱心捐赠列表
const getDonationListUrl = `/gateway/hc-neighbour/donation/getDonationList`;
//详情
const getDonationByIdUrl = `/gateway/hc-neighbour/donation/getDonationById`;
//删除
const deleteDonationUrl = `/gateway/hc-neighbour/donation/deleteDonation`;
export {
  applyCreateWorkList,
  getActivityListUrl,
  saveDonationUrl,
  getDonationListUrl,
  getDonationByIdUrl,
  deleteDonationUrl,
};
