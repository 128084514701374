import { render, staticRenderFns } from "./loveDonateList.vue?vue&type=template&id=40794db9&scoped=true"
import script from "./loveDonateList.vue?vue&type=script&lang=js"
export * from "./loveDonateList.vue?vue&type=script&lang=js"
import style0 from "./loveDonateList.vue?vue&type=style&index=0&id=40794db9&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40794db9",
  null
  
)

export default component.exports