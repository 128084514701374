import { mapHelper } from "@/utils/utils";

const auditStatusOps = [
  {
    value: 0,
    text: "待审核",
    class: "toexamine",
  },
  {
    value: 2,
    text: "审核不通过",
    class: "toexamineOut",
  },
  {
    value: 1,
    text: "审核通过",
    class: "toexamineEd",
  },
  {
    value: 3,
    text: "已放款",
    class: "toexamineEd",
  },
];
const { map: auditStatusOpsMap, setOps: auditStatusOpsOps } =
  mapHelper.setMap(auditStatusOps);
export { auditStatusOps, auditStatusOpsMap };
